import React from 'react';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container py-3">
          <div className="f-card">
            <div className="row justify-content-center">
              <div className="col-md-4"><img src="assets/g5.png" className='g5-img' alt="" /></div>
              <div className="col-md-10 mb-lg-5 mb-4 text-center">
                <h1 className='mb-lg-5 mb-4'>Are you fading Bera, anon?</h1>
                <a href="javascript:void(0)" className='primary-btn sn-btn'>Start Trading 
                <span className='soon'>Coming Soon</span></a>

              </div>
            </div>
          <div className="row">
            <div className="col-md-4 pe-lg-5 mb-md-0 my-3">
              <a href='javascript:void(0)' className="footer-logo d-md-inline-block d-flex align-items-center justify-content-between mb-4">
                <img src="assets/logo.svg" className='light-logo' alt="" />
              <div className="d-flex align-items-center d-md-none gap-4 ">
                <a href="https://discord.gg/GrizzlyFinance" target="_blank" rel="noopener noreferrer" className='f-link'><img src="assets/dc.svg" alt="" /></a>
                <a href="https://twitter.com/GrizzlyFinance_" target="_blank" rel="noopener noreferrer" className='f-link'><img src="assets/x.svg" alt="" /></a>
                <a href="https://medium.com/@GrizzlyFinance_" target="_blank" rel="noopener noreferrer" className='f-link'><img src="assets/m.svg" height={18} alt="" /></a>
              </div>
              </a>
              <p className='d-md-block d-none'>The liquidity hub of the Berachain. One mission: To 
              become the most rewarding place for you to park your DeFi assets.</p>
              
            </div>
            <div className="col-md-6 offset-lg-2">
              <div className="row">
              <div className="col-md-4 col-6 mb-4">
              <h5>Product</h5>
              <ul>
                <li><a href="javascript:void(0)" rel="noopener noreferrer" className='disable'>Testnet</a></li>
              </ul>
            </div>

            <div className="col-md-4 col-6 mb-4">
              <h5>Learn More</h5>
              <ul>
                {/* <li><a href="http://docs.grizzlyfinance.io/" target="_blank" rel="noopener noreferrer">Documentation</a></li> */}
                <li><a href="https://medium.com/@GrizzlyFinance_" target="_blank" rel="noopener noreferrer">Medium</a></li>
                <li><a href="https://discord.gg/GrizzlyFinance" target="_blank" rel="noopener noreferrer">Feedback</a></li>
              </ul>
            </div>

            <div className="col-md-4 col-6 d-md-block d-none">
              <h5>Contact</h5>
              <div className="d-flex align-items-center gap-4 mt-4">
                <a href="https://discord.gg/GrizzlyFinance" target="_blank" rel="noopener noreferrer" className='f-link'><img src="assets/dc.svg" alt="" /></a>
                <a href="https://twitter.com/GrizzlyFinance_" target="_blank" rel="noopener noreferrer" className='f-link'><img src="assets/x.svg" alt="" /></a>
                <a href="https://medium.com/@GrizzlyFinance_" target="_blank" rel="noopener noreferrer" className='f-link'><img src="assets/m.svg" height={18} alt="" /></a>
              </div>
            </div>
              </div>
              <p className='d-md-none d-block mb-2'>The liquidity hub of the Berachain. One mission: To 
              become the most rewarding place for you to park your DeFi assets.</p>
            </div>
            
          </div>
          <hr />
          <div className="sub-footer pt-2">
          <div className="row">
            <div className="col-md-6 mb-md-0 mb-2">
              <p className='mb-0'>© 2024 Grizzly Finance, All Right Reserved</p>
            </div>
            <div className="col-md-6 text-md-end">
            <div className="d-inline-flex align-tems-center gap-4">
            <a href="javascript:void(0)"  rel="noopener noreferrer">Terms & Conditions</a>
            <a href="javascript:void(0)"  rel="noopener noreferrer">Privacy Policy</a>
            </div>
            </div>
          </div>
          </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
