import React, { useEffect } from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link, useLocation } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import CountUp, { useCountUp } from 'react-countup';

const Home = () => {
  useCountUp({
    ref: 'counter',
    end: 1234567,
    enableScrollSpy: true,
    scrollSpyDelay: 2000,
  });

  useEffect(function () {
    Aos.init({ disable: 'mobile' });
  }, []);

  const location = useLocation();

  // useEffect(() => {
  // window.scrollTo(0, 0);
  // }, [location]);

  return (
    <>
      {/* Banner */}
      <div className="bg-grad">
        <section className="banner" id="home">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-9 mb-md-0 mb-4" data-aos="fade-up" data-aos-duration="1800">
                <h1 className="mb-0">
                DeFi and Liquidity hub <br /> for <span>strong Beras</span>
                </h1>
              </div>
                <div className="col-md-4 mt-4">
                <img src="assets/banner.webp" className='w-100' alt="" />
              </div>
              <div className="col-md-10 text-center">
                <p className="mb-lg-5 my-4">
                Natively integrated with Berachain's novel Proof-of-Liquidity mechanism, Grizzly allows liquidity 
                providers to truly go where no DeFi has gone before. With concentrated liquidity, Integral v4 
                integration, and PoL incentives that'll have you checking if it's legal - this is DeFi's new apex habitat.
                </p>
                <a href="javascript:void(0)" className='primary-btn sn-btn'>Start Trading 
                <span className='soon'>Coming Soon</span></a>
              </div>
              <div className="text-center mt-lg-5 mt-4">
              <img src="assets/bera.png" className="bera" alt="" />
              </div>
              
            </div>
          </div>
        </section>

       <section className='py-5 my-5'>
        <div className="container">
        <div className="g-card ">
          <div className="row align-items-center justify-content-center position-relative">
            <div className="col-md-4">
              <div className="">
            <img src="assets/g1.png" className=' g-img' data-aos="fade-right" data-aos-duration="1800" alt="" />
              </div>
            </div>
            <div className="col-md-5 offset-lg-1 py-lg-3" data-aos="fade-left" data-aos-duration="1800">
            <h3>Intro</h3>
                <p className='mb-0'>Let's be real - most DeFi products recycle the same old features with 
                some fancy marketing lipstick. But Grizzly is bringing massive protocol upgrades to rip 
                through the competition. See what terrifying DeFi climbs await</p>
            </div>
          </div>
              
              </div>
        </div>
       </section>

       <section className='py-5 my-5'>
        <div className="container">
        <div className="g-card ">
          <div className="row align-items-center justify-content-center position-relative">
          <div className="col-md-5  py-lg-3" data-aos="fade-left" data-aos-duration="1800">
            <h3>Concentrated Liquidity</h3>
                <p className='mb-0'>Calculate Your Capital Efficiency To The Last Satoshi. Why provide liquidity 
                blindly across every price range when you can be a surgical LP? Grizzly lets you stake targeted 
                ammunition into the most lucrative, thick liquidity pools</p>
            </div>
            <div className="col-md-4 offset-lg-1">
              <div className="">
            <img src="assets/g2.png" className='g-img' data-aos="fade-right" data-aos-duration="1800" alt="" />
              </div>
            </div>
            
          </div>
              
              </div>
        </div>
       </section>


       <section className='py-5 my-5'>
        <div className="container">
        <div className="g-card ">
          <div className="row align-items-center justify-content-center position-relative">
            <div className="col-md-4">
              <div className="">
            <img src="assets/g3.png" className='g3-img' data-aos="fade-right" data-aos-duration="1800" alt="" />
              </div>
            </div>
            <div className="col-md-5 offset-lg-1 py-lg-3" data-aos="fade-left" data-aos-duration="1800">
            <h3>Integral V4 Core</h3>
                <p className='mb-0'><b className='text-white fw-600'>Forged From DeFi's Most Battle-Tested Codebase</b> <br /> <br />

                We're not reinventing the wheel - we're just making it rounder, tougher and grippier for every 
                road condition. Grizzly embraces the heavyweight AMM tech that's proven itself across Ethereum and layer 2s.<br /> <br />

                Our core is powered by the rock-solid Integral v4 codebase, upgraded for Berachain's liquidity 
                mega-pools. If it can't handle the deepest liquidity demands, we're not interested</p>
            </div>
          </div>
              
              </div>
        </div>
       </section>

       <section className='py-5 my-5'>
        <div className="container">
        <div className="g-card ">
          <div className="row align-items-center justify-content-center position-relative">
          <div className="col-md-5  py-lg-3" data-aos="fade-left" data-aos-duration="1800">
            <h3>Leverage Native PoL</h3>
                <p className='mb-0'><b className='text-white fw-600'>Liquidity Mining Incentives That Make Speculators FOMO</b> <br /> <br />

                  With Grizzly's Proof-of-Liquidity synergies, LPs collect rewards heavier than a bear's midnight snack.<br /> <br />

                  Whether it's validator bribes, BGT reward streams, or LBPs that make wannabe AMMs soil themselves - we're 
                  the only DEX supercharging yields across Berachain's entire capital landscape</p>
            </div>
            <div className="col-md-4 offset-lg-1">
              <div className="">
            <img src="assets/g4.png" className='g4-img' data-aos="fade-right" data-aos-duration="1800" alt="" />
              </div>
            </div>
            
          </div>
              
              </div>
        </div>
       </section>



       

       
      </div>
    </>
  );
};

export default Home;
