import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

const Header = () => {
  const [stickyClass, setStickyClass] = useState('relative');
  const homeNav = () => {
    if (document.body.classList.contains('home-sm')) {
      document.body.classList.remove('home-sm');
    } else {
      document.body.classList.add('home-sm');
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setStickyClass('bg-pink') : setStickyClass('');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  return (
    <div>
      <nav className={`navbar navbar-expand-lg flex-column transition-all pt-lg-3 ${stickyClass}`}>
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="assets/logo.svg" className="light-logo" alt="" />
          </Link>
          <div className="d-lg-none d-inline-flex gap-2 align-items-center">
            <a href="javascript:void(0)" className="primary-btn sn-btn d-lg-none d-inline-flex h-auto px-3 py-2 fs-14">
                <span className='soon'>Coming Soon</span>
          Launch App 
              </a>
            <button className="navbar-toggler" type="button" onClick={() => {homeNav();}}>
              <img src="assets/menu.svg" className="" alt="" />
            </button>
          </div>

          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <div className="d-lg-none d-flex justify-content-between text-end p-4">
              <Link to="/" className="navbar-brand">
                <img src="assets/logo-dark.svg" alt="" />
              </Link>
              <a href="javascript:void(0)" onClick={() => {homeNav();}} className="cross-btn">
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            <ul className="navbar-nav align-items-center mx-auto px-4 pe-lg-5">
              <li className="nav-item">
                <a  href="javascript:void(0)" className="nav-link disable" onClick={() => {homeNav();}}>App</a>
              </li>
              <li className="nav-item">
                <a  href="https://medium.com/@GrizzlyFinance_" target="_blank" rel="noopener noreferrer" className="nav-link" onClick={() => {homeNav();}}>Blog</a>
              </li>
              <li className="nav-item">
                <a  href="https://medium.com/@GrizzlyFinance_" target="_blank" rel="noopener noreferrer" className="nav-link" onClick={() => {homeNav();}}>Learn</a>
              </li>
              {/* <li className="nav-item">
                <a  href="http://docs.grizzlyfinance.io/" target="_blank" rel="noopener noreferrer" className="nav-link" onClick={() => {homeNav();}}>Docs</a>
              </li> */}
            </ul>
            <div className="d-flex pt-lg-0 pt-5 px-4 px-md-0">
              <a href="javascript:void(0)" className="primary-btn sn-btn py-2">
                Launch App
                <span className='soon'>Coming Soon</span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
